import { render, staticRenderFns } from "./Game3-1.vue?vue&type=template&id=a6ecdc7c&scoped=true&"
import script from "./Game3-1.vue?vue&type=script&lang=js&"
export * from "./Game3-1.vue?vue&type=script&lang=js&"
import style0 from "./Game3-1.vue?vue&type=style&index=0&id=a6ecdc7c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6ecdc7c",
  null
  
)

export default component.exports